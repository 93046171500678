import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TheReferralSystem = () => (
	<Layout>
		<SEO title="Lupus referral system and pathway in Australia" />
		<h1>Lupus referral system in Australia</h1>
		<p>
			Proper management with your doctor is important for the effective
			management of lupus. Before you can see a Specialist (most often an
			Immunologist or Rheumatologist), you will need a referral from your
			General Practitioner (GP). GP’s have the authority to write you a
			referral, once the clinical need for one has been established. GP’s will
			often do their own tests prior to diagnosing lupus and if/when a
			Specialist referral is required, these results can be shared with them.
		</p>
		<p>
			Your GP and Specialist will work together with you to manage your lupus in
			an optimal manner. It is really important that you have a good working
			relationship with your treating team of health professionals.
		</p>
		<p>
			Your GP and Specialist will work together with you to manage your lupus in
			an optimal manner. It is therefore important that you have a good working
			relationship with your treating team of professionals.
		</p>
		<p>
			In the event that you need references, we have published{" "}
			<Link to="/useful-contacts">a list of GPs and Specialists</Link> who have
			been recommended by the local Lupus WA community as having some knowledge
			and understanding in seeing patients with lupus.
		</p>
		<p>Other points to be mindful of:</p>
		<ul>
			<li>
				Referrals have expiry dates (usually 12 months). You may need to get
				additional referrals from your GP if (as is likely) your management with
				a Specialist spans a long duration.
			</li>
			<li>
				General information on Medicare referrals can be found{" "}
				<a
					rel="noopener noreferrer"
					target="_blank"
					className="link"
					href="https://www.servicesaustralia.gov.au/organisations/health-professionals/subjects/referring-and-requesting-medicare-services#a2"
				>
					here
				</a>
				.
			</li>
			<li>
				If you are being referred to a specialist in the public system, your GP
				will have some additional criteria to follow{" "}
				<a
					href="https://ww2.health.wa.gov.au/Articles/A_E/Central-Referral-Service-guide-for-referrers"
					rel="noopener noreferrer"
					target="_blank"
					className="link"
				>
					as published by the health department here
				</a>
				. You do not need to worry about this aspect - your GP will be aware and
				refer you accordingly.
			</li>
		</ul>
	</Layout>
)

export default TheReferralSystem
